import React from "react";
import FrontPage from "../index";
import "./Home.css";

export default function Home(props) {
  return (
    <div className="home-container" id={props.id || ""}>
      <FrontPage.Header />
      <FrontPage.Profile />
      <FrontPage.Footer/>
    </div>
  );
}
