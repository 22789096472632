import Home from "../pages/frontpage/Home/Home";
import AboutUs from "../pages/frontpage/AboutUs/AboutUs";
import Resume from "../pages/frontpage/Resume/Resume";
import Testimonial from "../pages/frontpage/Testimonial/Testimonial";
import ContactUs from "../pages/frontpage/ContactUs/ContactUs";

export const TOTAL_SCREENS = [
  {
    screen_name: "Home",
    component: Home,
  },
  {
    screen_name: "About Us",
    component: AboutUs,
  },
  {
    screen_name: "Resume",
    component: Resume,
  },
  {
    screen_name: "Testimonial",
    component: Testimonial,
  },
  {
    screen_name: "Contact",
    component: ContactUs,
  },
];

export const GET_SCREEN_INDEX = (screen_name) => {
  if (!screen_name) return -1;
  for (let i = 0; i < TOTAL_SCREENS.length; i++) {
    if (TOTAL_SCREENS[i].screen_name === screen_name) return i;
  }
  return -1;
};
