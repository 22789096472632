import "./App.css";
import FrontPage from "./pages/frontpage/frontpageContainer";

function App() {
  return (
    <div className="App">
      <FrontPage />
    </div>
  );
}

export default App;
