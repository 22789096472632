
import Profile from "../frontpage/Home/Profile/Profile"
import Header from "./Home/Header/Header";
import Footer from "../frontpage/Home/Footer/Footer"
import PageNotFound from "../NotFound";


const FrontPage = {
    Header,
    Profile,
    Footer,
    PageNotFound
}


export default FrontPage;